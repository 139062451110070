import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const WhyusData = [
  {
    image: image1,
    brief:
      "Get more visibility and analytical abilities. PRD offers tools and a verified methodology to unlock the valuable data stored within ERP systems to enhance revenue, decrease costs, increase efficiency and take strategic decisions.",
    name: 'Analytics',

  },
  {
    image: image2,
    brief: 'Handle more users, data volume and intricacy effortlessly. With PRD INFOTECH you will be able to take on new markets and manage exponential increases in transactional capacity, workload and quantity of data.',
    name: 'Scalability',

  },
  {
    image : image3,
    brief:' Your data is secure in our hands even though you host it to a cloud. PRD offers data security and protection developed to help secure your data from databases to cloud enterprises and more.',
    name: 'Data Security',

  }
];
