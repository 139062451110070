import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Digital.css'
import digital1 from '../../../assets/digital1.jpg'
import digital2 from '../../../assets/digital2.jpg'
import digital3 from '../../../assets/digital3.jpg'

const Digital = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
      <div className="digitalback">

</div>
       <section id="gtco-special-dishes" className="bg-grey section-padding">
    <div className="container">
    <div data-aos="zoom-in" className="row">
            <h1 style={{marginTop:'5rem',marginBottom:'10rem',textAlign:'center'}}>DIGITAL MARKETING</h1>
            <div data-aos="zoom-in" className="col">
<img className='digitalimg' src={digital1 }alt="" />
            </div>
            <div className="col">
                <h2 style={{marginTop:'3rem'}}>Digital Marketing</h2>
                <p className='softtop-1'>Only way of marketing with the help of electronic devices such as desktops, computers, mobile phones, etc with the help of internet connections is termed digital marketing. Digital marketing is one of the most popular components of marketing to sell products digitally without any physical interaction with the users. Now digital marketing has fully changed the way of marketing when we actually relate it with the ancient way. Due to the increase in technology digital marketing is booming now as it started taking its peak during the 2000s. Digital Marketing has the potential to capture a huge crowd digitally because the stats tell us more than 3 quarters of American people come online daily. Through Digital marketing you can actually take the advantage of these 3 quarters.</p>
            </div>
        </div>
        <div className="section-content">
            <div data-aos="zoom-in"className="row mt-5">
                <div data-aos="zoom-in" className="col-lg-5 col-md-6 align-self-center py-5">
                    <h2 className="special-number">01.</h2>
                    <div className="dishes-text">
                        <h3><span>WHAT IS</span><br/> SOCIAL MEDIA MARKETING</h3>
                        <p className="pt-3">Social media is not just for spending time and having interaction with your friends. If you go with its advantages you can organize a huge platform to drive traffic in any specific ads. Some of the popular social media marketing platforms are YouTube, Facebook, Instagram, and LinkedIn. Social media involves billions of active users on daily basis and how does it sound to reach the billions of people in a single day? Social media is a popular way of getting the attention of customers and let them aware of your brands.</p>
                    
                      
                    </div>
                </div>
                <div data-aos="zoom-in" className="col-lg-5 offset-lg-2 col-md-6 align-self-center mt-4 mt-md-0">
                    <img style={{height:'22rem'}}src={digital2} alt="" className="img-fluid shadow w-100"/>
                </div>
            </div>
            <div data-aos="zoom-in"className="row mt-5">
                <div data-aos="zoom-in" className="col-lg-5 col-md-6 align-self-center order-2 order-md-1 mt-4 mt-md-0">
                    <img src={digital3} alt="" className="img-fluid shadow w-100"/>
                </div>
                <div className="col-lg-5 offset-lg-2 col-md-6 align-self-center order-1 order-md-2 py-5">
                    <h2 className="special-number">02.</h2>
                    <div className="dishes-text">
                        <h3><span>WHAT IS  </span><br/> SEARCH ENGINE OPTIMIZATION</h3>
                        <p className="pt-3">Search engine optimization is an element in raking any website in the google top pages. Having the art of doing SEO can generate massive lead success. SEO requires a lot of research and producing quality content based on the research. The strategic way of putting the keyword in the content is termed SEO. However, there is no guide and rule to rank higher on google all depends on the predictions.</p>
                       
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
    </div>
  )
}

export default Digital