import React,{Fragment,useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
	useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <Fragment>
    <footer className="footer">
  	 <div className="container">
  	 	<div className="row">
  	 		<div className="footer-col">
  	 			<h4>PRD INFOTECH</h4>
  	 			<ul>
				   <li><Link to={"/"}>Home</Link></li>
  	 				<li><Link to={"/about"}>About us</Link></li>
  	 				<li><Link to={"/contact"}>Contact</Link></li>
  	 				<li><Link to={"faq"}>FAQ</Link></li>
  	 				
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>Services</h4>
  	 			<ul>
  	 				<li><Link  to="/web">Web Development</Link></li>
  	 				<li><Link  to="/seo">Digital Marketing</Link></li>
  	 				<li><Link  to="/software">Software Development</Link></li>
  	 				<li><Link  to="/mobile">App Development</Link></li>
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>Solutions</h4>
  	 			<ul>
  	 				<li><Link to="/barcode">Barcode Solutions</Link></li>
  	 				<li><Link to="/accounting">ERP Solutions </Link></li>
  	 				<li><Link to="/crm">CRM Solutions</Link></li>
  	 				<li><Link to="/Jwellary">Jwellary Management Solutions</Link></li>
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>follow us</h4>
  	 			<div className="social-links">
  	 				<a href="https://www.facebook.com/wincidtechnologies" target="blank"><i className="fab fa-facebook-f"></i></a>
  	 				<a href="#"><i className="fab fa-twitter"></i></a>
  	 				<a href="#"><i className="fab fa-instagram"></i></a>
  	 				<a href="#"><i className="fab fa-linkedin-in"></i></a>
  	 			</div>
  	 		</div>
  	 	</div>
  	 </div>
  </footer>
  </Fragment>
  )
}

export default Footer