import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Section2.css'
import aboutimg from '../../../assets/aboutimg.jpg'

const Section2 = () => {
   useEffect(()=>{
      Aos.init({duration:2000});
    },[] );
  return (
    <div>
<div className="about_section layout_padding">
         <div className="container">
            <h1 data-aos="fade-right"className=" l">About Us</h1>
            <p data-aos="fade-right" className="about_text">Transforming Your Business Process Through Cloud ERP Solution</p>
            <div className="about_section_2">
               <div className="row">
                  <div data-aos="fade-right" className="col-lg-6">
                    <div className='rectangle'></div>
                     <div className="about_image"><img src={aboutimg}/></div>
                  </div>
                  <div className="col-lg-6">
                     <div className="about_taital_main">
                        <p className="lorem_text">The PRD InfoTech is the result of the passion and desire of a finance veteran to transform the way companies run their business process and improve the lives of business professionals across the globe. Manual business processes most often create a slump in employee productivity due to reasons such as duplicity of data and redundant tasks that build fatigue and open up a Pandora's box of problems. PRD is built on decades of knowledge and experience of its founders across industry types, geography and company sizes, and can be used by aspiring companies as a plug and play Cloud ERP Platform</p>
                        <div className="read_bt"><a href="#">Read More</a></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}

export default Section2