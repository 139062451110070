import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './WebDevelopment.css'
import services1 from '../../../assets/webdevv1.png'
import services2 from '../../../assets/webdevv2.png'
import services3 from '../../../assets/webdevv3.svg'
import services4 from '../../../assets/webdev6.png'
import services5 from '../../../assets/webdevv4.png'
import services6 from '../../../assets/webdevv5.png'
import services7 from '../../../assets/webdevv6.svg'
import services8 from '../../../assets/webdevv7.svg'
import webdev2 from '../../../assets/webdev2.jpeg'
import webdev3 from '../../../assets/webdev3.png'
import webdev4 from '../../../assets/webdev4.png'
import webdev5 from '../../../assets/webdev5.png'

 

const WebDevelopment = () => {
   useEffect(()=>{
      Aos.init({duration:2000});
    },[] );
  return (
    <div>
      <div className="webdevback">
  
      </div>

      <div  id="service" className="services">
      <div >
    
             <div style={{padding:'7rem'}} className="row webdev-1">
               <div data-aos="zoom-in" className="col">
                  <h2 style={{paddingBottom:'1.8rem'}}>Web Development</h2>
               <p style={{fontSize:'1.2rem'}}>Improve Business Credibility. Build Your Business. Enhance Your Online Presence. Build a Strong Relationship with Your Market. Efficient Way to Promote Your Business. Boost Your Reputation as an Authority. Create a Tool for Sales Generation. Showcase Your Work.</p>
            
               </div>
               <div data-aos="zoom-in"className="col">
               <img src={webdev2} alt="" />
               </div>
             </div>
             <div style={{ backgroundColor:'black',padding:'7rem', color:'white'}} className="row webdev-2">
             
               <div data-aos="zoom-in" className="col">
               <img className=" webdevimg-1 "style={{height:'25rem', paddingLeft:'6rem'}} src={webdev3} alt="" />
               </div>
               <div data-aos="zoom-in"className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Enhance Your Online Presence</h2>
               <p style={{fontSize:'1.2rem'}}>
                  <ul>
                 <li>By optimizing your web pages, it will be easier for people to find your content.</li>
                 <li>Unlike a brick- and- mortar business that operates 9- to- 5, a website never closes shop. It is open 24/7, even on holidays.</li>
                  </ul>
                  </p>
               </div>
             </div>
             <div style={{padding:'7rem'}} className="row webdev-3 ">
               <div data-aos="zoom-in"className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Improve Business Credibility</h2>
                  <p style={{fontSize:'1.2rem'}}>
                  <ul>
                 <li>A simple way to view a website is that it is your online business address.</li>
                 <li>This is where your customers, clients and associates go to find you on the Internet.</li>
                 <li>It legitimizes your business and improves your credibility.</li>
                 <li>It gives your business an identity and is virtual proof that it exists.</li>
                  </ul>
                  </p>
            
               </div>
               <div data-aos="zoom-in"className="col">
               <img className=" webdevimg-4 "src={webdev4} alt="" />
               </div>
             </div>
             <div style={{backgroundColor:'black',padding:'7rem', color:'white'}} className="row webdev-4">
             
             <div data-aos="zoom-in"className="col ">
             <img className=" webdevimg-5"src={webdev5} alt="" />
             </div>
             <div data-aos="zoom-in" className="col">
                <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Showcase Your Work</h2>
             <p style={{fontSize:'1.2rem'}}>
                <ul>
               <li>Open a personal website and include a page that showcases your body of work.</li>
               <li>A website will give you the venue to present samples of your work.</li>
                </ul>
                </p>
             </div>
           </div>
           <div style={{paddingLeft:'7rem'}}  className="row">
            <div data-aos="zoom-in"className="col-md-7">
               <div style={{paddingTop: '6rem'}} className="titlepage ">
                  <h2 >Benefits Of Web Development</h2>
               </div>
            </div>
         </div>
         <div data-aos="zoom-in" style={{padding:'7rem'}}  className="row webdev-5">
            <div className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services1} alt="#" />
                  <h3>Online Presence 24/7</h3>
                  <p>Having a website means customers are always able to find you. </p>
               </div>
            </div>
            <div  className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services2} alt="#" />
                  <h3>Information Exchange</h3>
                  <p>A website provides a quick and easy way of communicating information between buyers and sellers.</p>
               </div>
            </div>
            <div className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services3} alt="#" />
                  <h3>Credibility</h3>
                  <p>These are useful tools to share crucial information about your business with customers and answer all the What’s and Why’s that they may have. </p>
               </div>
            </div>
            <div className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services4} alt="#" />
                  <h3>Consumer Insights</h3>
                  <p>Analytic tools allow you to identify who your typical customer is, how they found you, what they like, and adapt your business to maximise purchases through your site. </p>
               </div>
            </div>
            <div className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services5} alt="#" />
                  <h3>It Cuts Costs</h3>
                  <p>As well as simply displaying information, you can also use your website to sell goods & servicess directly to consumers. </p>
               </div>
            </div>
            <div className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services6}alt="#" />
                  <h3>Market Expansion</h3>
                  <p>As your site is accessible to anyone all over the world, the ability to break through geographical barriers has never been easier.</p>
               </div>
            </div>
            <div className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services7} alt="#" />
                  <h3>Advertising</h3>
                  <p>Tools like Google AdWords or advertising on Facebook give you the power to reach customers with much more accuracy and reliability than with traditional offline advertising methods.</p>
               </div>
            </div>
            <div className="col-md-3 col-sm-6">
               <div className="services_box">
                  <img src={services8} alt="#" />
                  <h3>Customer services Online</h3>
                  <p>Websites provide an easier way to handle customer services.</p>
               </div>
            </div>
            </div>
            <div className="col-md-12">
               {/* <a className="read_more" href="#">Read More</a> */}
             </div>
      
      </div>
   </div> 
   
    </div>
  )
}

export default WebDevelopment