import React,{useEffect} from 'react'
import './Accounting.css'
import Aos from 'aos';
import "aos/dist/aos.css";
import accounting11 from '../../../assets/accounting11.jpg'
import accounting2 from '../../../assets/accounting2.jpg'
import accounting3 from '../../../assets/accounting3.jpg'
import accounting4 from '../../../assets/accounting4.jpg'

const Accounting = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
        <div className="accountback">

</div>
          <section id="about">
      <div className="container" data-aos="fade-up">
      <div className="row">
            <h1 style={{marginTop:'5rem',marginBottom:'10rem',textAlign:'center'}}>ACCOUNTING SOFTWARE</h1>
            <div data-aos="zoom-in"className="col">
<img className='softtopimg' src={accounting4 }alt="" />
            </div>
            <div data-aos="zoom-in"className="col">
                <h2 style={{marginTop:'3rem'}}>ERP Software</h2>
                <p className='asofttop-1'>Enterprise resource planning (ERP) clarifies a type of software which organizations use to operates their business operations on day-to-day basis such as business accounting, procurement, project management, risk management and compliance, and supply chain operations. A fully fledged and complete ERP solution which includes enterprise business activity management, software which helps to plan, budget, predict, and report on an organization’s financial results. ERP management processes tie together a multitude of business processes and enable the flow of data between them. By capturing a client’s shared transactional data from various sources, ERP systems are bound to eliminate data duplication and provide data integrity with a one gate way of truth. </p>
            </div>
        </div>
        <header className="section-header">
          <h2>WHY DO YOU NEED ERP IN YOUR BUSINESS?</h2>
          <p> The ERP management system calculates the day to day service of your company like accounting, product management, risk management, and for any other business activity. It's great news for people running their businesses in Delhi as they can get ERP management software at such a great offer from us. So if you are waiting to get it installed by your company then hurry up it is the best time to get it installed.</p>
        </header>

        <div className="row about-cols">

          <div className="col-md-4" data-aos="zoom-in">
            <div className="about-col">
              <div className="img">
                <img src={accounting11} alt="" className="img-fluid"/>
                {/* <div className="icon"><i className="bi bi-bar-chart"></i></div> */}
              </div>
              {/* <h2 className="title"><a href="#">Our Mission</a></h2> */}
              <p>
              You are using software to manage one of these tasks but because of that the growth or expansion has come to a halt and here is your chance of letting a erp software manage a system and expand and grow your business.
              </p>
            </div>
          </div>

          <div className="col-md-4" data-aos="zoom-in">
            <div className="about-col">
              <div className="img">
                <img src={accounting2}alt="" className="img-fluid"/>
                {/* <div className="icon"><i className="bi bi-brightness-high"></i></div> */}
              </div>
              {/* <h2 className="title"><a href="#">Our Plan</a></h2> */}
              <p>
              You are dealing with disparate systems and with the change in technology you see that the old system does not fit anymore and you are tired of wasting time and resources to make that system work .
              </p>
            </div>
          </div>

          <div className="col-md-4" data-aos="zoom-in">
            <div className="about-col">
              <div className="img">
                <img src={accounting3} alt="" className="img-fluid"/>
                {/* <div className="icon"><i className="bi bi-calendar4-week"></i></div> */}
              </div>
              {/* <h2 className="title"><a href="#">Our Vision</a></h2> */}
              <p>
              If you and your staff cannot meet the expectations of your customer because of a lack of tools and that is affecting your business, it is a good idea to invest in erp software technology .
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>
    </div>
  )
}

export default Accounting