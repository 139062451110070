import React from 'react'
import './Section1.css'
import vdo1 from '../../../assets/Videoo1.mp4'
import Header from '../../Header/Header'

const Section1 = () => {
  return (
    <div>
      {/* <div style={{ paddingTop:'2rem' ,paddingLeft:'2rem',paddingRight:'2rem'}}>
      <Header/>
      </div>   */}
      <div className="">
<video className='videoTag1' autoPlay loop muted>

    <source src={vdo1} type='video/mp4' />
    </video>
    </div>
    </div>
  )
}

export default Section1