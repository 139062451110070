import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Jwellary.css'

import jwel4 from '../../../assets/jwel3.jpg'
import jwel3 from '../../../assets/jwel4.jpg'
import jwel5 from '../../../assets/jwel5.jpg'
import jwel6 from '../../../assets/jwel6.jpg'

const Jwellary = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[] );
  return (
    <div>
<div className="Jwelback"></div>

 
  <div style={{backgroundColor:'white',padding:'7rem'}} className="row  billing-1">
               <div data-aos="zoom-in"className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>JEWELLERY SOFTWARE</h2>
               <p style={{fontSize:'1.2rem'}}>
                <ul>
                  <li>Our software: completely controls your Business transactions with Jewellery shop Owner Mobile App.</li>
                  <li>Product: We have one of the fast moving Jewellery Software products of India since 2019.</li>
                  <li>Our Expertise: More than 10 Years of Research & development in Jewellery Business Management.</li>
                </ul>
               </p>
            
               </div>
               <div data-aos="zoom-in"className="col">
               <img style={{height:'28rem'}}className="billingimg-12" src={jwel3} alt="" />
               </div>
             </div>
             <div style={{ backgroundColor:'black',padding:'7rem', color:'white'}} className="row  billing-2">
             
               <div data-aos="zoom-in"className="col">
               <img className=" billingimg-23"style={{height:'25rem', paddingLeft:'6rem'}} src={jwel4} alt="" />
               </div>
               <div data-aos="zoom-in"className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Why us?</h2>
               <p style={{fontSize:'1.1rem'}}>
               In the growing world of population, it gets too much complicated to manage the choice of every person. Every person has their own unique needs so, to go with the better products they always search for simplicity. With the help of jewellery management software, one can easily get rid of managing the account or solution of billing. Jewellery management software is specially designed for leading the growth of your company by promoting it to a huge crowd. 
                  </p>
               </div>
             </div>
             <div style={{backgroundColor:'white',padding:'7rem'}} className="row billing-4">
               <div data-aos="zoom-in"className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Solution to increase Sales</h2>
               <p style={{fontSize:'1.1rem'}}>
               One and only solution to increase sales by minimizing tons of losses in the jewellery industry. The jewellery industry makes you reach people globally and establish a connection between you and the users. The Jewellery management software has many tools to automatically run the entire industry. It doesn’t require lots of manpower to manage it. 
               </p>
            
               </div>
               <div data-aos="zoom-in" className="col">
               <img className="billingimg-4 "src={jwel5} alt="" />
               </div>
             </div>
           <div style={{ backgroundColor:'black',padding:'7rem', color:'white'}} className="row billing-3">
             
             <div data-aos="zoom-in"className="col">
             <img className="billingimg-3"style={{height:'25rem', paddingLeft:'6rem'}} src={jwel6} alt="" />
             </div>
             <div data-aos="zoom-in"className="col">
                <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Helps in Keeping Track</h2>
             <p style={{fontSize:'1.1rem'}}>
             The owner can keep track of the engagement of products so, that later they can easily connect them with a trendy product. However, it also pushes recommendations to the users while users watch any products.
                </p>
             </div>
           </div>
    </div>
  )
}

export default Jwellary