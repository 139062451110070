import React,{useEffect} from 'react'
import './Seo.css'
import seo1 from '../../../assets/seo2.jpg'
import Aos from 'aos';
import "aos/dist/aos.css";
const Seo = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>

        <div className="seoback">
            
        </div>
 <div className="container-fluid pt-5 aboutseo">
        <div className="container">
            <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{maxWidth: '600px'}}>
                {/* <h2 className="text-primary font-secondary">About Us</h2> */}
                <h1 data-aos="fade-in" data-aos-delay="100" className="display-4 text-uppercase">Search Engine Optimization</h1>
            </div>
            <div  data-aos="zoom-in"className="row gx-5">
                <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: '400px'}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100" src={seo1} style={{objectFit: 'cover'}}/>
                    </div>
                </div>
                <div data-aos="zoom-in"className="col-lg-6 pb-5">
                    <h4 className="mb-4">Search Engine Optimization which is the practice of increasing the quantity and quality of traffic to your website through organic search engine results.</h4>
                    {/* <p className="mb-5">Once you have the right people clicking through from those search engine results pages (SERPs), more traffic is better.</p> */}
                    <div className="row g-5">
                        <div data-aos="zoom-in"className="col-sm-6">
                            <div className="d-flex align-items-center justify-content-center bgcolorr border-inner mb-4" style={{width: '90px', height: '90px'}}>
                                <i className="fa fa-search fa-2x text-white"></i>
                            </div>
                            <h4 className="text-uppercase serp">SERP</h4>
                            <p className="mb-0">Ads make up a significant portion of many SERPs. Organic traffic is any traffic that you don't have to pay for.</p>
                        </div>
                        <div data-aos="zoom-in"className="col-sm-6">
                            <div className="d-flex align-items-center justify-content-center bgcolorr border-inner mb-4" style={{width:' 90px', height:' 90px'}}>
                                <i className="fa fa-refresh fa-2x text-white"></i>
                            </div>
                            <h4 className="text-uppercase organictraffic">Organic Traffic</h4>
                            <p className="mb-0">Once you have the right people clicking through from those search engine results pages (SERPs), more traffic is better.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
     <div className="container-fluid service position-relative px-5 mt-5" style={{marginBottom: '135px'}}>
        <div className="container">
            <div className="row g-5">
                <div data-aos="zoom-in"className="col-lg-4 col-md-6">
                    <div className="bgcolorr border-inner text-center text-white p-5">
                        <h4 className="text-uppercase mb-3">Accurate inventory software</h4>
                        <p>Accurate inventory software results in better customer service</p>
                        
                    </div>
                </div>
                <div data-aos="zoom-in"className="col-lg-4 col-md-6">
                    <div className="bgcolorr border-inner text-center text-white p-5">
                        <h4 className="text-uppercase mb-3">Good inventory management</h4>
                        <p>Good inventory management will forge better vendor relationships</p>
                        
                    </div>
                </div>
                <div data-aos="zoom-in" className="col-lg-4 col-md-6">
                    <div className="bgcolorr border-inner text-center text-white p-5">
                        <h4 className="text-uppercase mb-3">Reduced operation costs </h4>
                        <p>Reduced operation costs will help you to invest more in marketing</p>
                        
                    </div>
                </div>
                
                
                
            </div>
        </div>
    </div>
    </div>
  )
}

export default Seo