import React,{useEffect} from 'react'
import './Reasons.css'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import Aos from 'aos';
import "aos/dist/aos.css";

const Reasons = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
    },[] );
  return (
    <div>
<div className="Reasons" id='Reasons'>
<div className="left-r">
  <img data-aos="flip-right" src={image3} alt="" />
  <img  data-aos="slide-down" src={image2} alt="" />
  <img data-aos="slide-up" src={image1} alt="" />
</div>
<div className="right-r">
<span data-aos="flip-left">Grow Business Through Automation</span>
<div data-aos="zoom-in-left" className="details-r">
    <p>Your Unified software suite for all your business needs all under one place, a complete ERP solution. Design to fill the all problem behind Business management. PRD is built on decades of knowledge and experience of its founders across industry types, geography and company sizes, and can be used by aspiring companies as a plug and play Cloud ERP Platform. PRD platform is a multi-industry cloud ERP platform that can fit into your business process and provide you with the flexibility of auto-scaling to your business as it grows. 
</p>
</div>
</div>
</div>
    </div>
  )
}

export default Reasons