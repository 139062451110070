import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Header.css'
import Logo from '../../assets/prd_logo.png'
import {NavLink} from 'react-router-dom'

const Header = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[] );
  return (
    <div>
        {/* <div className="header">
        <img src={Logo} alt="" className='logo'/>
        <ul className='Header-menu'>
            <li>
            <NavLink to="/">Home</NavLink>
            </li>
            <li>
            <NavLink to="/about">Company</NavLink>
            </li>
            <li className="nav-item dropdown">
          <a className="nav-link " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <NavLink to="/products">Products</NavLink>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="#"><NavLink to="/billing">Billing Software</NavLink></a></li>
            <li><a className="dropdown-item" href="#"><NavLink to="/web">Web Development</NavLink></a></li>
          <li><a className="dropdown-item" href="#"><NavLink to="/software">Software Development</NavLink></a></li>
          <li><a className="dropdown-item" href="#"><NavLink to="/mobile">MObile App Development</NavLink></a></li>
            <li><a className="dropdown-item" href="#"><NavLink to="/inventory">Inventory Management Software</NavLink></a></li>
          <li><a className="dropdown-item" href="#"><NavLink to="/accounting">Accounting Solution</NavLink></a></li>
          <li><a className="dropdown-item" href="#"><NavLink to="/crm">CRM</NavLink></a></li>
            <li><a className="dropdown-item" href="#"><NavLink to="/digital">Digital marketing</NavLink></a></li>
          <li><a className="dropdown-item" href="#"><NavLink to="/seo">SEO</NavLink></a></li>
          <li><a className="dropdown-item" href="#"><NavLink to="/jwellary">Jewellary management Software</NavLink></a></li>
            <li><a className="dropdown-item" href="#"><NavLink to="/barcode">Barcode Solutions</NavLink></a></li>
      
          </ul>
        </li>
            <li>
            <NavLink to="/contact">Contact</NavLink>
            </li>
            <li>
            <NavLink to="/faq">FAQ</NavLink>
            </li>
        </ul>
        
        </div> */}
 <header id="header" className="fixed-top d-flex align-items-cente">
          <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">


          <img className="logo me-auto me-lg-0 img-fluid"src={Logo} style={{width:'5rem'}} alt="" />
            <h2 className="logo me-auto me-lg-0"><NavLink to="/">PRD INFOTECH PVT LTD</NavLink></h2>
    
            
      
            <nav className=" navbar-expand-lg ">
 
 <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
   <span className="navbar-toggler-icon"></span>
 </button>
 <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className=" navbar-nav mr-auto">
                <li  className="nav-item active"> <NavLink className="nav-link scrollto" to="/">Home</NavLink></li>
                <li  className="nav-item"><NavLink className="nav-link scrollto" to="/about">About</NavLink></li>
                <li className="nav-item dropdown">
          <li className="nav-link  "  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <NavLink style={{color:'#0d6efd'}} to="/products">Products</NavLink>
          </li>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" ><NavLink to="/billing">Billing Software</NavLink></a></li>
            <li><a className="dropdown-item" ><NavLink to="/web">Web Development</NavLink></a></li>
          <li><a className="dropdown-item" ><NavLink to="/software">Software Development</NavLink></a></li>
          <li><a className="dropdown-item" ><NavLink to="/mobile">Mobile App Development</NavLink></a></li>
            <li><a className="dropdown-item" ><NavLink to="/inventory">Inventory Management Software</NavLink></a></li>
          <li><a className="dropdown-item" ><NavLink to="/accounting">Accounting Solution</NavLink></a></li>
          <li><a className="dropdown-item" ><NavLink to="/crm">CRM</NavLink></a></li>
            <li><a className="dropdown-item" ><NavLink to="/digital">Digital marketing</NavLink></a></li>
          <li><a className="dropdown-item" ><NavLink to="/seo">SEO</NavLink></a></li>
          <li><a className="dropdown-item" ><NavLink to="/jwellary">Jewellary management Software</NavLink></a></li>
            <li><a className="dropdown-item" ><NavLink to="/barcode">Barcode Solutions</NavLink></a></li>
      
          </ul>
        </li>
                <li  className="nav-item"><NavLink className="nav-link scrollto" to="/contact">Contact</NavLink></li>
                <li  className="nav-item"> <NavLink className="nav-link scrollto" to="/faq">FAQ</NavLink></li>
                
               
             
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
              </div>
            </nav>
            <NavLink to ="/contact" className="book-a-table-btn scrollto d-none d-lg-flex">Mail us</NavLink>
      
          </div>
        </header>
    </div>
  )
}

export default Header