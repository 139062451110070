import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import Header from '../Header/Header'
import './Faq.css'


const Faq = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[] );
  return (
    <div>
     
        <div className='faq-background'>
        {/* <div style={{ paddingTop:'2rem' ,paddingLeft:'2rem',paddingRight:'2rem'}}>
      <Header/>
      </div>   */}
        </div>
        <section  data-aos="zoom-in"className="accord">
            <h1>Frequently Asked Questions</h1>
<div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      What kind of businesses can use EStock-ERP for billing?
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      eStock-ERP is a billing and Inventory management software to manage your business. Almost every retailer and service provider can use eStock-ERP for managing their business. Manufacturing businesses can also use eStock-ERP to manage their business.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       
How to Select an ERP System?

      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      As your firms grow and seek to work niftier, a vast issue often emerges. Selecting the right system for your firm is positively a significant and intricate undertaking. Most importantly must flex to fit business with existing software in terms of integration and compatibility. ERP System has different features and functions, Do your research based on the pricing of available systems, including implementation and integration costs.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        
	Do you provide training of the eStock-ERP software?

      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      Yes, we provide online/offline training to our customers. Also, any business/technical queries will be answered by us even later on.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
     
	Do you provide customized eStock-ERP software?

      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      
      Yes, we provide customized software as per the requirement of the customer and need.

      </div>
    </div>
  </div>
 
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        
      Is eStock-ERP Available in online mode?

      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      Yes eStock-ERP is Available in both Offline and Online mode.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      
	Where I can contact for technical support or any other query elated to eStock-ERP?

      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      Mobile number: 90266607886
Mail to: infoestockerp@gmail.com
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       

      What are the charges of eStock-ERP?


      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      eStock-ERP has four plans based on different features I.e.
Basic plan
Pro Plan
Advanced Plan
Premium Plan
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        
	
      What are the payment modes available for the purchase of eStock-ERP?


      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      We Accept cheque, net banking and wallets such as Paytm, Google Pay, Phone Pay etc.
      </div>
    </div>
  </div>
</div>
</section>
    </div>
   
  )
}

export default Faq