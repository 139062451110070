import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './BillingSoftware.css'
import bill1 from '../../../assets/bill1.png'
import bill3 from '../../../assets/bill3.png'
import bill4 from '../../../assets/bill4.png'
import bill5 from '../../../assets/bill5.png'
import bill6 from '../../../assets/bill6.png'

const BillingSoftware = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[] );
  return (
    <div>
 <div className="Billback"></div>
 <section data-aos="zoom-in"className="agency_section layout_padding-bottom">
    <div className="agency_container ">
      <div className="box ">
        <div className="detail-box">
          <div className="heading_container">
            <h2>
            Billing Software
            </h2>
          </div>
          <p>
          We Provide billing software can be any software designed to handle time and billing tracking as well as invoicing customers for services and products. It can track the days worked by employees.
          </p>
        
        </div>
      </div>
    </div>
  </section>
 
  <div data-aos="zoom-in"style={{backgroundColor:'white',padding:'7rem'}} className="row  billing-1">
               <div className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Automated Billing Management</h2>
               <p style={{fontSize:'1.2rem'}}>
                <ul>
                  <li>Complete Automation from In-warding to Sale of goods</li>
                  <li>Bar code/QR Code identifications</li>
                  <li>Automated Sale generation based on Billing</li>
                </ul>
               </p>
            
               </div>
               <div className="col">
               <img  className="billingimg-1" src={bill3} alt="" />
               </div>
             </div>
             <div data-aos="zoom-in"style={{ backgroundColor:'black',padding:'7rem', color:'white'}} className="row  billing-2">
             
               <div className="col">
               <img className=" billingimg-2"style={{height:'25rem', paddingLeft:'6rem'}} src={bill4} alt="" />
               </div>
               <div className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Enhanced Workflow</h2>
               <p style={{fontSize:'1.2rem'}}>
                  <ul>
                 <li>Reduce time consumed to find a particular item in the warehouse</li>
                 <li>Analyze and allocate the most demanded item to the nearest location</li>
                 <li>Smooth management of stocks with better controls</li>
                 <li>Increase productivity by allocating the right employee for the right task</li>
                  </ul>
                  </p>
               </div>
             </div>
             <div data-aos="zoom-in"style={{backgroundColor:'white',padding:'7rem'}} className="row billing-4">
               <div className="col">
                  <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Higher Billing Turnover</h2>
               <p style={{fontSize:'1.2rem'}}>
                <ul>
                  <li>Determine and invest in the most profitable item</li>
                  <li>Gain an Upper hand during business negotiations with accurate data</li>
                  <li>Depend on Billing closing stock reports</li>
                </ul>
               </p>
            
               </div>
               <div className="col">
               <img className="billingimg-4 "src={bill5} alt="" />
               </div>
             </div>
           <div data-aos="zoom-in" style={{ backgroundColor:'black',padding:'7rem', color:'white'}} className="row billing-3">
             
             <div className="col">
             <img className="billingimg-3"style={{height:'25rem', paddingLeft:'6rem'}} src={bill6} alt="" />
             </div>
             <div className="col">
                <h2 style={{marginTop:'7rem',paddingBottom:'1.8rem'}}>Improve Brand Visibility</h2>
             <p style={{fontSize:'1.2rem'}}>
                <ul>
               <li>Accurate Billing software results in better customer service</li>
               <li>Good Billing management will forge better cutomers relationships</li>
               <li>Never miss anything with 360-degree tracking</li>
                </ul>
                </p>
             </div>
           </div>
           <section data-aos="zoom-in"className="service_section layout_padding">
    <div className="container">
      <div className="heading_container">
        <h2>
        Unique Features
        </h2>
        <p style={{fontSize:'1.25rem'}}>
        The best billing software for your business should carry the following features: ‐
        </p>
      </div>
      <div className="row">
        <div className="col-lg-6 ">
          <div className="img-container tab-content">
            <div className="img-box tab-pane fade show active" id="img1" role="tabpanel">
              <img src={bill1} alt="" />
            </div>
         
          </div>
        </div>
        <div className="col-lg-6">
          <div className="detail-container nav nav-tabs" id="myTab" role="tablist">
            <div className="detail-box active" id="img1-tab" data-toggle="tab" href="#img1" role="tab" aria-selected="true">
              <h4>
              Creation of <br/>New Invoice.
              </h4>
            </div>
            <div className="detail-box" id="img2-tab" data-toggle="tab" href="#img2" role="tab" aria-selected="false">
              <h4>
              Creating<br/> Customer<br/> records.

              </h4>
            </div>
            <div className="detail-box" id="img2-tab" data-toggle="tab" href="#img3" role="tab" aria-selected="false">
              <h4>
              Multiple<br/> payment<br/> option.
              </h4>
            </div>
           
            <div className="detail-box" id="img2-tab" data-toggle="tab" href="#img4" role="tab" aria-selected="false">
              <h4>
              Sale Report.
              </h4>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </section>
 
    </div>
  )
}

export default BillingSoftware