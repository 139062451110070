import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Itsol.css'



const Itsol = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
        <div className="featuresss">
      <div data-aos="zoom-in-up"className="featuress">
      <h1>Our Platform Features</h1>
      <h6>With a bundle of ERP solutions to choose every firm gets confused about what to select. PRD helps enterprises make quicker decisions, serve their clients better and work more efficiently overall by automating processes and workflows.</h6>
      </div>
   <div className="container it-sol">
    {/* <div className="row"> */}
      <div data-aos="zoom-in-up" className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>01</h2>
            <h3>Streamlined Data Flow</h3>
            <p>A better view of data in all areas of the business helps to make faster and more accurate decisions. Without hassle, able to compare and analyze data with ease across business functions.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    <div data-aos="zoom-in-up" className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>02</h2>
            <h3>Scalability</h3>
            <p>To ensure the ongoing success of your business advance, having our system in place that competently and elegantly accommodates future growth.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    <div data-aos="zoom-in-up" className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>03</h2>
            <h3>Efficiency</h3>
            <p>Improve efficiency with multi-level approvals can reduce time and effort required by your team to carry out their daily activities by dropping operating costs and error-free.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    {/* </div> */}
    {/* <div className="row"> */}
    <div data-aos="zoom-in-up" className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>04</h2>
            <h3>Productivity</h3>
            <p>Enhance business productivity and that integrates all the departments of the business and helps them communicative with each other it helps the productivity in scale.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    <div data-aos="zoom-in-up"className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>05</h2>
            <h3>Insights & Reports</h3>
            <p>Reports that are intuitive, actionable and relevant. Stay top with competitors. Insights help to understand your business's weaknesses, guaranteeing stronger performance over time.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    <div data-aos="zoom-in-up" className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>06</h2>
            <h3>Accounting</h3>
            <p>Streamline their accounting needs through the integration of advanced data such as GST Compliant accounting, Automatic TDS calculation and multi-location GST calculation.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    <div data-aos="zoom-in-up" className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>07</h2>
            <h3>Real-time Notifications</h3>
            <p>Fast and accurate communication enables quick approvals/rejections and automatically sends notifications to check the work and progress.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    <div data-aos="zoom-in-up" className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>08</h2>
            <h3>Automation</h3>
            <p>Forget manual follow-up with the automated flow. Stay focused and tasks through countless processes are error-free and reduce costs.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    {/* </div> */}
    {/* <div className="row"> */}
    <div data-aos="zoom-in-up"className="col-lg-4-md-4-sm-2">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>09</h2>
            <h3>Data Security</h3>
            <p>Advanced security, Ensure the utmost data secured with built-in safety protections and hosted in the cloud.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div>
    {/* <div className="col-lg-4">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>05</h2>
            <h3>Insights & Reports</h3>
            <p>Reports that are intuitive, actionable and relevant. Stay top with competitors. Insights help to understand your business's weaknesses, guaranteeing stronger performance over time.</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
    </div> */}
    {/* <div className="col-lg-4">
    <div className="card">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className="content">
            <h2>01</h2>
            <h3>Title</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error veniam, non amet sint numquam ex voluptatem ipsum repellat laudantium nihil itaque, aliquam commodi odio fugit ipsa maxime porro vero inventore!</p>
            <button className='btn'>Read More</button>
        </div>
    </div>
   </div> */}
   </div>
    {/* // </div> */}
    </div>
    </div>
  )
}

export default Itsol