import React,{useEffect} from 'react'
import './CRM.css'
import Aos from 'aos';
import "aos/dist/aos.css";
import crm1 from '../../../assets/crm1.jpg'
import crm2 from '../../../assets/crm2.jpg'
import crm3 from '../../../assets/crm3.jpg'
import crm4 from '../../../assets/crm4.jpg'

const CRM = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
              <div className="crmback">

</div>
 
<section id="about-crm" className="about-crm section-bg">
      <div className="container">

        <div className="row gy-4">
          <div className="image col-xl-5"></div>
          <div className="col-xl-7">
            <div className="content d-flex flex-column justify-content-center ps-0 ps-xl-4">
              <h3 data-aos="fade-in" data-aos-delay="100">The Path of Custom CRM Development Is Not Easy. But Look at the Benefits!</h3>
              <p style={{color:'white'}} data-aos="fade-in">
              PRD Infotech is ready to go the extra mile to make sure all your efforts and investments into a custom CRM are rewarded at their best.
              </p>
              <div className="row gy-4 mt-3">
                <div className="col-md-6 icon-box" data-aos="fade-up">
                  <i className="bx bx-receipt"></i>
                  <h4>Simplicity</h4>
                  <p>You may find the default functionality of CRM platforms excessive. We offer to tune your CRM’s functional filling and UI to minimize users’ learning curve and facilitate the system’s adoption.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-cube-alt"></i>
                  <h4>Cost-efficiency in long run</h4>
                  <p>You succeed at each step of customer relationship building and can expect a higher return in the form of a higher lead conversion rate, faster case resolution in customer service, and so on.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-images"></i>
                  <h4>Ownership</h4>
                  <p>If you have a fully custom CRM, you can grow your team without thinking of skyrocketing subscription fees.</p>
                </div>
                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-shield"></i>
                  <h4>Scalability</h4>
                  <p>Get new features when you need them, not when a vendor decides to upgrade the platform.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="services" className="services section-bg">
      <div className="container">

        <div className="section-title">
          <h2 data-aos="fade-in">Our Custom CRM Development Process: Well-Organized and Fast</h2>
          
        </div>

        <div className="row">
          <div className="col-md-6 d-flex" data-aos="fade-right">
            <div className="card-crm">
              <div className="card-crm-img">
                <img style={{width:'69rem'}} src={crm1} alt="..."/>
              </div>
              <div className="card-crm-body">
                <h5 className="card-crm-title"><a href="">Discovery</a></h5>
                <p className="card-crm-text">Analyze your current processes that a CRM will cover: the scope of manual work, inconveniences, time-consuming activities.
Spot process optimization opportunities.
Elicit requirements for your CRM via interviews and business process analysis.</p>
                {/* <div className="read-more"><a href="#"><i className="bi bi-arrow-right"></i> Read More</a></div> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex" data-aos="fade-left">
            <div className="card-crm">
              <div className="card-crm-img">
                <img style={{width:'69rem'}} src={crm2}alt="..."/>
              </div>
              <div className="card-crm-body">
                <h5 className="card-crm-title"><a href="">CRM concept design</a></h5>
                <p className="card-crm-text">Create usage scenarios for different CRM user roles.
Plan a functional specification.
Create an integration plan.
Document CRM architecture detailing optimal programming tools and technologies for all the components.</p>
                {/* <div className="read-more"><a href="#"><i className="bi bi-arrow-right"></i> Read More</a></div> */}
              </div>
            </div>

          </div>
          <div className="col-md-6 d-flex" data-aos="fade-right">
            <div className="card-crm">
              <div className="card-crm-img ">
                <img style={{height:'23rem'}}src={crm3} alt="..."/>
              </div>
              <div className="card-crm-body">
                <h5 className="card-crm-title"><a href="">CRM development</a></h5>
                <p className="card-crm-text">Implement all the planned functionality, be it CRM platform customization or building all CRM functional modules from scratch.
Set up APIs to integrate a CRM with other business systems.
Test the CRM’s functionality, integration, compatibility</p>
                {/* <div className="read-more"><a href="#"><i className="bi bi-arrow-right"></i> Read More</a></div> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex" data-aos="fade-left">
            <div className="card-crm">
              <div className="card-crm-img">
                <img src={crm4} alt="..."/>
              </div>
              <div className="card-crm-body">
                <h5 className="card-crm-title"><a href="">CRM support</a></h5>
                <p className="card-crm-text">Provide user training, answer user questions and concerns.
Upon demand, provide L1-L3 support services.</p>
                {/* <div className="read-more"><a href="#"><i className="bi bi-arrow-right"></i> Read More</a></div> */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
            </div>
    
  )
}

export default CRM