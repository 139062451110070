import './App.css';
import { Route, Routes } from "react-router-dom";
import Hero from './components/Hero/Hero'
import Reasons from './components/Reasons/Reasons';
import Whyus from './components/Whyus/Whyus';
import Itsol from './components/Itsol/Itsol'
import Service from './components/Service/service';
import Clients from './components/Clients/Clients';
import Footer from './components/Footer/Footer';
import About from './components/Aboutus/About';
import Faq from './components/Faq/Faq';
import Contact from './components/Contact/Contact';
import AppDevelopment from './components/Page/App development/AppDevelopment';
import Seo from './components/Page/SEO/Seo';
import WebDevelopment from './components/Page/WebDeveleopment/WebDevelopment';
import BillingSoftware from './components/Page/Billing Software/BillingSoftware';
import SoftwareDevelopment from './components/Page/Software devlopment/SoftwareDevelopment';
import Home from './components/Home';
import Header from './components/Header/Header';
import { Fragment } from 'react';
import Inventory from './components/Page/Inventory/Inventory';
import Accounting from './components/Page/Accounting/Accounting';
import CRM from './components/Page/CRM/CRM';
import Digital from './components/Page/Digital/Digital';
import Jwellary from './components/Page/Jwellary/Jwellary';
import Barcode from './components/Page/Barcode/Barcode';





function App() {
  return (
    <div className='App'>
       <Fragment>  
             <Header/>
       <Routes>
      <Route path="/" element={<Home/>} />
       <Route path="/about" element={<About/>} />
       <Route path="/contact" element={<Contact/>} />
       <Route path="/billing" element={<BillingSoftware/>} />
       <Route path="/web" element={<WebDevelopment/>} />
       <Route path="/software" element={<SoftwareDevelopment/>} />
       <Route path="/seo" element={<Seo/>} />
       <Route path="/mobile" element={<AppDevelopment/>} />
       <Route path="/faq" element={<Faq/>} />
       <Route path="/billing" element={<BillingSoftware/>} />
       <Route path="/inventory" element={<Inventory/>} />
       <Route path="/accounting" element={<Accounting/>} />
       <Route path="/crm" element={<CRM/>} />
       <Route path="/digital" element={<Digital/>} />
       <Route path="/jwellary" element={<Jwellary/>} />
       <Route path="/barcode" element={<Barcode/>} />
  

   
         
         {/* <Section1/>
         <Section2/>
         <Section3/>
         <Section4/>
         <Section5/>
         <Footer/>  */}

         {/* <Faq/> */}

         {/* <Contact/> */}
    
{/* <AppDevelopment/> */}

{/* <WebDevelopment/> */}
{/* <BillingSoftware/> */}

     

   
         
        
</Routes>
<Footer/>
</Fragment>

         </div>
  );
}


export default App;
