import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Inventory.css'
import invent3 from '../../../assets/invent1.jpg'
import invent1 from '../../../assets/invent3.jpg'


const Inventory = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
        <section id="gtco-special-dishes" className="bg-grey section-padding">
        <div className="container">
 <div className="row">
            <h1 style={{marginTop:'5rem',marginBottom:'10rem',textAlign:'center'}}>INVENTORY MANAGEMENT SOFTWARE</h1>
            <div data-aos="zoom-in"className="col">
<img className='inventimg' src={invent1 }alt="" />
            </div>
            <div data-aos="zoom-in"className="col">
                <h2 style={{marginTop:'3rem'}}>Inventory Software</h2>
                <p className='softtop-1'>The inventory management software is a system of software for calculating the orders, deals, discounts and tracking the delivery etc. Inventory software is also used to create bills of products, work order or other business related stuff. So basically it's great software for tracking business activities. It provides the service and tracks everything about the services 24×7 also it provides 100% satisfaction with working regulation.</p>
            </div>
        </div>
        <div style={{marginTop:'6rem'}} className="row">
            
            
            <div data-aos="zoom-in"className="col">
                <h2 style={{marginTop:'3rem'}}>WITH INVENTORY MANAGEMENT MODULE:</h2>
                <p className='softtop-1'> Erp Software Manages all multiple locations of warehouses through a unique database which protects and prevents the formation of data corruption, data loss and avoids duplication.
 Erp Software Very keenly monitor dealings in multiple currencies through our inventory management ERP module and ensure smooth transactions.
Erp Software Maintain your warehouse inventory based on wide range of quality and characteristics which you could define and customize, for seamless picking.</p>
            </div>
            <div data-aos="zoom-in" className="col">
<img className='inventimg' src={invent3 }alt="" />
            </div>
            
        </div>
        <div data-aos="zoom-in" className="card-invent">
            <div className='inventimg2'>
              
            </div>
            <div>
                <p>The Erp Software inventory management software module is an vital part and a crucial component of all ERP systems. This cloud-hosted module is the backbone to manage all your businesses activities which have warehouse inventory management and storage functions, assisting warehouse associates in optimizing space and reducing recurring operational costs. Apart from assisting in tracking and tracing all stock in hand and providing updates upto date related to stock in hand availability, the inventory management module in Erp Software also automates your business processes seamlessly and monitors all inventory transactions..</p>
            </div>
        </div>
    </div>
    </section>
    </div>
  )
}

export default Inventory