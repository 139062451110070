import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import globalStorage from '../../data/serviceData';
import { BsFillLightbulbFill } from "react-icons/fa";
import './Service.css'
function Service(params) {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
    return(
        <>
        <section className="service-box-area ">
            <div className="container">
                <div data-aos="flip-up" className="row wow fadeInUp">
                    <div className="col-lg-12">
                        <div className="section-title mb-55 text-center">
                            <div className="border-c-bottom st-2">
                                
                                <h1>Business Made Simple</h1>
                            </div>
                            <h5>IT Solutions</h5>
                        </div>
                    </div>
                </div>
                <div data-aos="zoom-in-up" className="row wow fadeInUp">
                {globalStorage.Service.serviceCat.map(data =>
                    <div className="col-lg-4 col-md-6 col-sm-2">
                        <div className="service-box-single mb-40">
                            <div className="service-box-content">
                                <div className="service-box-content-icon">
                                <i className={data.img}></i>
                                </div>
                                <div className="service-box-content-text">
                                    <h4><a  style={{color:'black'}} href={data.url}>{data.heading}</a></h4>
                                    <p>{data.details}</p>
                                </div>
                                <button className="btn my-4"> {data.button}</button>
                            </div>
                        </div>
                    </div>
                     )}
                </div>
            </div>
        </section>
        </>
    );
}
export default Service;