import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Section3.css'
import icon1 from '../../../assets/icon-1.svg'
import icon2 from '../../../assets/icon-2.svg'
import icon3 from '../../../assets/icon-3.svg'
import icon4 from '../../../assets/icon-4.svg'

const Section3 = () => {
   useEffect(()=>{
      Aos.init({duration:2000});
    },[] );
  return (
    <div>
 <div className="services_section layout_padding">
         <div className="container">
            <h1 data-aos="fade-up-right"className="services_taital">How is PRD different from others?</h1>
            {/* <p className="about_text">It is a long established fact that a reader will be distracted by the readable content of a page when</p> */}
            <div className="services_section_2">
               <div className="row">
                  <div  data-aos="zoom-in"className="col-lg-6">
                     <div className="icon_box">
                        <div className="icon_1"><img src={icon2}/></div>
                     </div>
                     <h4 className="selection_text">Smart And Secure (S)</h4>
                     <p className="ipsum_text">We understand how important privacy is to our customers - our cloud ERP solution comes with an extensive layer of security across all modules. To ensure there is hassle-free data migration, our software is compatible with most platforms and the modules can be customized as per the client's requirements.</p>
                     <div className="icon_box">
                        <div className="icon_1"><img src={icon1}/></div>
                     </div>
                     <h4 className="selection_text">Accessible (A)</h4>
                     <p className="ipsum_text">Never miss a thing! Access and analyze your data from various departments all at one place. Out on a vacation and had an epiphany? Just access all your data from your laptop and give life to your ideas on the go.</p>
                  </div>
                  <div  data-aos="zoom-in"className="col-lg-6">
                     <div className="icon_box">
                        <div className="icon_1"><img src={icon4}/></div>
                     </div>
                     <h4 className="selection_text">Flexible In Reporting (F)</h4>
                     <p className="ipsum_text">Reports are essential for forecasting in every organization. Our cloud ERP solution will help you to create customized reports with just a few clicks. Enjoy the flexibility of creating accurate and meaningful reports without depending on other unnecessary records.</p>
                     <div className="icon_box">
                        <div className="icon_1"><img src={icon3}/></div>
                     </div>
                     <h4 className="selection_text">End To End Solution (E)</h4>
                     <p className="ipsum_text">PRD is your one-stop destination to help streamline your business process and get complete control of your organization. The ERP platform is seamlessly integrated with the entire business process.</p>
                  </div>
                
               </div>
            </div>
         </div>
      </div>

    </div>
  )
}

export default Section3