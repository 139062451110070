import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Appdevelopment.css'
import appdev1 from '../../../assets/appdev1.png'
import appdev2 from '../../../assets/appdev2.png'
import appdev3 from '../../../assets/appdev3.png'
import appdev4 from '../../../assets/appdev4.svg'

const AppDevelopment = () => {
   useEffect(()=>{
      Aos.init({duration:2000});
    },[] );
  return (
    <div>
      <div className="appbackimg">

      </div>

{/* about  */}
      <div className="about">
         <div className="container_width">
            <div className="row d_flex">
                   <div data-aos="zoom-in"className="col-md-7">
                  <div className="titlepage text_align_left">
                     <h2> Mobile App Development</h2>
                     <p>Mobile apps can let users set up their preferences at the start, based on which users can be served with customized content. Apps can also track and observe user engagement, and use it to offer custom recommendations and updates to the users. Furthermore, they can also identify location of the users in real-time to provide geography-specific content.
                     </p>
                   
                  </div>
               </div>
               <div data-aos="zoom-in"className="col-md-5">
                  <div className="about_img text_align_center">
                     <figure><img src={appdev1} alt="#"/></figure>
                  </div>
               </div>
              
            </div>
         </div>
      </div>
      {/* end about  */}
     {/* coronata  */}
      <div className="coronata">
         <div className="container">
            <div className="row d_flex grid">
               <div data-aos="zoom-in" className="col-md-7">
                  <div className="coronata_img text_align_center">
                     <figure><img className="appdevimg-1"src={appdev2} alt="#"/></figure>
                  </div>
               </div>
               <div data-aos="zoom-in" className="col-md-5 oder1">
                  <div className="titlepage text_align_left">
                     <h2>Mobile Application Needs</h2>
                     <ul className='mobileappdev'>
                        <li>
Increased Visibility</li>
<li>Cultivate Customer Loyalty</li>
<li>Better Brand Recognition</li>
</ul>  
                    
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/* end coronata  */}
   
        {/* protect  */}
      <div className="protect">
         <div className="container">
            <div className="row">
               <div data-aos="zoom-in" className="col-md-12">
                  <div className="titlepage text_align_center">
                     <h2>Mobile App Development</h2>
                     <p>Mobile apps can let users set up their preferences at the start, based on which users can be served with customized content. Apps can also track and observe user engagement, and use it to offer custom recommendations and updates to the users. Furthermore, they can also identify location of the users in real-time to provide geography-specific content.
                     </p>
                  </div>
               </div>
            </div>
         </div>
           <div className="protect_bg">
         <div className="container">
             <div className="row">
               <div data-aos="zoom-in" className="col-md-12">
                   {/* Demos  */}
                  <div className="owl-carousel owl-theme">
                     <div className="item">
                        <div className="protect_box text_align_center">
                          <div className="desktop">
                             <i><img src={appdev3} alt="#"/></i>
                           <h3>Enhanced Workflow</h3>
                           <div className='text_align_center'>
                           <ul className='mobileappdev'>
                        <li>Provide More Value to Your Customers</li>
                        <li>Cultivate Customer Loyalty</li>
                        <li>Better Brand Recognition</li>
                         </ul> 
                          </div>
                          </div>
                         
                        </div>
                     </div>
                     <div data-aos="zoom-in"className="item">
                          <div className="protect_box text_align_center">
                          <div className="desktop">
                             <i><img src={appdev4} alt="#"/></i>
                           <h3>Improve Brand Visibility</h3>
                           <span> <ul className='mobileappdev'>
                        <li>Accurate inventory software results in better customer service</li>
                        <li>Good inventory management will forge better vendor relationships</li>
                        <li>Never miss anything with 360-degree tracking</li>
                        <li>Reduced operation costs will help you to invest more in marketing</li>
                         </ul>  </span>
                          </div>
                      
                        </div>
                     </div>
                   
                  </div>
               </div>
            </div>
          </div>
      </div>
         </div>
    </div>
  )
}

export default AppDevelopment