import React from 'react'
import Itsol from './Itsol/Itsol'
import Hero from './Hero/Hero'
import Reasons from './Reasons/Reasons'
import Whyus from './Whyus/Whyus'
import Service from './Service/service'
import Clients from './Clients/Clients'


const Home = () => {
  return (
    <div>
        <Hero/>
       <Reasons/>
       <Whyus/>
         <Service/>
         <Itsol/>
         <Clients/>


    </div>
  )
}

export default Home