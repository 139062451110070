import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Section5.css'

const Section5= () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
        <section id="" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 col-sm-offset-2 text-center">
                        <div className="section-title">
                            <h1 >Our <span className="alo"> Core Values</span></h1> 
                            <span className="border-line"></span>
                            <p className="lead subtitle-caption">
                                {/* we are a creative agency located in <span className="colored-text">sydney, australia.</span> It is a long established fact that a reader will be distracted by the readable content. */}
                                PRD platform is a multi-industry cloud ERP platform that can fit into your business process and provide you with the flexibility of auto-scaling to your business as it grows. It is an affordable all-in-one solution that is easy to implement in just a few days.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div data-aos="fade-down-left" className="col-sm-6 margin-bottom30">
                        <div className="feature-icon-wrap clearfix">
                            <div className="left-side-icon">
                                <i className="fa fa-lightbulb-o front-icon"></i>
                                <i className="fa fa-lightbulb-o back-icon"></i>
                            </div>
                            <div className="features-text-right">
                                <h3>Innovation</h3>                         
                                <p>
                                We focus on ideas that change the way small and medium enterprises handle their business process. We have significantly reduced implementation times by making our software plug and play.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-down-left"className="col-sm-6 margin-bottom30">
                        <div className="feature-icon-wrap clearfix">
                            <div className="left-side-icon">
                                <i className="fa fa-cog front-icon"></i>
                                <i className="fa fa-cog back-icon"></i>
                            </div>
                            <div className="features-text-right">
                                <h3>Great support</h3>                         
                                <p>
                                Our highly trained experts at PRD Infotech are able to offer our customers top-notch service & quick solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div data-aos="fade-down-left"className="col-sm-6 margin-bottom30">
                        <div className="feature-icon-wrap clearfix">
                            <div className="left-side-icon">
                                <i className="fa fa-users front-icon"></i>
                                <i className="fa fa-users back-icon"></i>
                            </div>
                            <div className="features-text-right">
                                <h3>Customer Success</h3>                         
                                <p>
                                We dedicate ourselves to our customers' success. We believe in growing together by actively listening to their needs and providing outstanding customer service.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-down-left"className="col-sm-6 margin-bottom30">
                        <div className="feature-icon-wrap clearfix">
                            <div className="left-side-icon">
                                <i className="fa fa-handshake-o front-icon"></i>
                                <i className="fa fa-handshake-o back-icon"></i>
                            </div>
                            <div className="features-text-right">
                                <h3>Equality</h3>                         
                                <p>
                                We respect and value employees from every background. We are firm believers of employees being a very important factor for the success of any organization.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
      
    </div>
  )
}

export default Section5