import { FaAdn } from "react-icons/fa";
const langJSON = {
Service: {
    serviceCat: [
      {
        img: "fa fa-credit-card",
        details:
          "We Provide Billing Software for made for Indian retailers, Small and Big Businessmen..",
        heading: "Billing Software",
        button: "Explore More",
        url:"/graphic-designing"
      },
      {
        img: "fa fa-laptop",
        details:
          "Our team of web developers helps your business stand out of the crowd in the online marketplace...",
        heading: "Website Development",
        button: "Explore More",
        url:"/web-development"
      },
      {
        img: "fa fa-mobile",
        details:
          "We have expertise in multifarious games and apps development that will be helpful...",

        heading: "App Development",
        button: "Explore More",
        url:"/app-development"
      },
      {
        img: "fa fa-volume-down",
        details:
          "We advertise at high levels through digital channels such as search engines, websites, social media...",
        heading: "Digital Marketing",
        button: "Explore More",
        url:"/digital-marketing"
      },
      {
        img: "fa fa-user",
        details:
          "We provide Customer Relationship Management Software. through CRM you connect with your valueable client...",

        heading: "CRM Development",
        button: "Explore More",
        url:"/CRM"
      },
      {
        img: "fa fa-line-chart",
        details:
          "We provide you an accounting solution, through which you can track your business...",
        heading: "Accounting Solution",
        button: "Explore More",
        url:"/Ecommerce"
      },
      {
        img: "fa fa-search",
        details:
          "We Provide Search Engine Optimization for Grow your website and Grow your Business...",
        heading: "SEO",
        button: "Explore More",
        url:"/seo"
      },
      {
        img: "fa fa-ravelry",
        details:
          "We provide you an accounting solution, through which you can track your business...",
        heading: "Jewellary Management Software",
        button: "Explore More",
        url:"/chat-bot"
      },
      {
        img: "fa fa-code",
        details:
          "We offer cross-platform desktop app development services to build and upgrade desktop software...",
        heading: "Software Development",
        button: "Explore More",
        url:"/software-development"
      }
    ]
  }
}
  export default langJSON;