import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Clients.css'
import client1 from '../../assets/client1.png'
import client2 from '../../assets/client2.png'
import client3 from '../../assets/client3.png'
import client4 from '../../assets/client4.png'
import client5 from '../../assets/client5.png'
import client6 from '../../assets/client6.png'
import client7 from '../../assets/client7.png'
import client8 from '../../assets/client8.png'
import client9 from '../../assets/client9.png'

const Clients = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
        <div className="client">
<div className="card">
    <h1>Our Clients</h1>
   <h4>Happy Customers makes us Proud</h4>
    <div className="row client-img">
        <div data-aos="fade-in" data-aos-delay="100" className="col">
            <img src={client1} alt="" />
        </div>
        <div  data-aos="fade-in" data-aos-delay="100"className="col">
            <img src={client2} alt="" />
        </div>
        <div data-aos="fade-in" data-aos-delay="100"className="col">
            <img src={client3} alt="" />
        </div>
        <div data-aos="fade-in" data-aos-delay="100"className="col">
            <img src={client4} alt="" />
        </div>
        <div data-aos="fade-in" data-aos-delay="100"className="col">
            <img src={client5} alt="" />
        </div>
      
    </div>
    <div className="row client-img2">
    <div data-aos="fade-in" data-aos-delay="100"className="col">
            <img src={client6} alt="" />
        </div>
        <div data-aos="fade-in" data-aos-delay="100" className="col">
            <img src={client7} alt="" />
        </div>
        <div data-aos="fade-in" data-aos-delay="100"className="col">
            <img src={client8} alt="" />
        </div>
        <div data-aos="fade-in" data-aos-delay="100"className="col">
            <img src={client9} alt="" />
        </div>
    </div>
</div>
</div>
    </div>
  )
}

export default Clients