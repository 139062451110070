import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Section4.css'
import vision from '../../../assets/vision.jpeg'
import mission from '../../../assets/mission.jpg'



const Section4 = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>

<div className="container-fluid  visionmision">
<div className="container ">
    <div className="row gx-0 mb-3 mb-lg-0">
        <div className="col-lg-6 my-lg-5 py-lg-5">
            <div className="about-start p-5">
            <span></span>
        <span></span>
        <span></span>
        <span></span>
                <h1 className="display-5 mb-4">
Vision</h1>
                <p>To become the world's 1st Plug and Play Cloud ERP solution provider that auto-scales with your business as it grows, without the need for any significant customization and investment.To become an affordable cloud ERP solution that isn't a financial burden for smaller companies.To create an automation platform for various repetitive and menial tasks across business functions.</p>
                {/* <a href="" className="btn btn-secondary rounded-pill py-md-3 px-md-5 mt-4">Contact Us</a> */}
            </div>
        </div>
        <div className="col-lg-6">
            <div className="position-relative h-100">
                <img className="position-absolute w-100 h-85" src={vision} />
            </div>
        </div>
    </div>
    <div className="row gx-0">
        <div className="col-lg-6" >
            <div className="position-relative h-100">
                <img className="position-absolute w-100 h-80" src={mission}/>
            </div>
        </div>
        <div className="col-lg-6 my-lg-5 py-lg-5">
            <div className="about-end  p-5">
            <span></span>
        <span></span>
        <span></span>
        <span></span>
                <h1 className="display-5 mb-4"> Our Mission</h1>
                <p>Our mission is to improve the lives of business professionals across the globe. For this, we have developed a plug and play cloud ERP platform which is completely integrated across business functions.We aim to provide a customizable Cloud ERP solution to companies at an affordable cost. </p>
                {/* <a href="" className="btn btn-secondary rounded-pill py-md-3 px-md-5 mt-4">Get A Quote</a> */}
            </div>
        </div>
    </div>
</div>
</div>
    </div>
  )
}

export default Section4