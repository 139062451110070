import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './SoftwareDevelopment.css'
import soft1 from '../../../assets/soft1.jpg'
import soft2 from '../../../assets/soft2.png'
import softtop from '../../../assets/softtop.png'

const SoftwareDevelopment = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
       
<section id="gtco-special-dishes" className="bg-grey section-padding">
    <div className="container">
    <div className="row">
            <h1 style={{marginTop:'5rem',marginBottom:'10rem',textAlign:'center'}}>SOFTWARE DEVELOPMENT</h1>
            <div data-aos="zoom-in"className="col">
<img className='softtopimg' src={softtop }alt="" />
            </div>
            <div data-aos="zoom-in"className="col">
                <h2 style={{marginTop:'3rem'}}>Management Software</h2>
                <p className='softtop-1'>Are you experiencing a lack of resource in executing your business? No, more to worry now because production management software has all the effective tools to promote the projects in a great way. It is best known for the better management of the production and keeping track of peak, downtime, efficiency, and profit of your business. The best solution for every small and large business is to keep their production track managed. The software is capable of generating a huge income from their end. Production management software is mainly used by large producing factories, and various top multi-national companies to provide their comfortability in keeping the record of production. Everything in the software run automatically to handle all the ongoing process and optimization for better production efficiency. The interface of production management software is crystal clear and simple which can easily execute all your project from start to end.</p>
            </div>
        </div>
        <div className="section-content">
            <div data-aos="zoom-in"className="row mt-5">
                <div className="col-lg-5 col-md-6 align-self-center py-5">
                    <h2 className="special-number">01.</h2>
                    <div className="dishes-text">
                        <h3><span>WHAT IS</span><br/> PRODUCTION MANAGEMENT SOFTWARE?</h3>
                        <p className="pt-3">A software that is mainly licensed for maximizing your business productivity as well as maximizing your total revenue. The purpose of production management software is clear as it minimizes the efforts of large businessmen in managing their huge production. Manufacturing any good pass with too much complex process from beginning to end and handling them efficiently is an impossible task without having the right tools in your hand. But stepping in the production management software provides you with tons of powerful tools to recreate the business productivity and functionality in effective ways. All the inventory of the industry can be easily tracked with the production software. </p>
                    
                      
                    </div>
                </div>
                <div className="col-lg-5 offset-lg-2 col-md-6 align-self-center mt-4 mt-md-0">
                    <img src={soft1} alt="" className="img-fluid shadow w-100"/>
                </div>
            </div>
            <div data-aos="zoom-in"className="row mt-5">
                <div className="col-lg-5 col-md-6 align-self-center order-2 order-md-1 mt-4 mt-md-0">
                    <img src={soft2} alt="" className="img-fluid shadow w-100"/>
                </div>
                <div className="col-lg-5 offset-lg-2 col-md-6 align-self-center order-1 order-md-2 py-5">
                    <h2 className="special-number">02.</h2>
                    <div className="dishes-text">
                        <h3><span>FEATURES OF  </span><br/> PRODUCTION MANAGEMENT SOFTWARE</h3>
                        <p className="pt-3">You all know how complicated is to keep the records from the beginning of manufacturing goods to the end of delivering them to the wholesaler. To pass all these challenges production management software is going to shake hands with you because it has many powerful tools to track your complete inventory.
It becomes very easy to distribute the production and access previous and real-time records for easy handling of all the databases.
The software is capable of delivering complete analytics and accurate manufacturing data to keep an eye on complete inventory.</p>
                       
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </div>
  )
}

export default SoftwareDevelopment