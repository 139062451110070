import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import Header from '../Header/Header'
import './Hero.css'
import{useTypewriter} from 'react-simple-typewriter'
import vdo from '../../assets/videooo.mp4'
import { motion } from "framer-motion";
import NumberCounter from 'number-counter';


const Hero = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[] );
  const [text]= useTypewriter({
    words:['Full Of ERP Solutions Growing your Business with us'],
    loop:[true]
    
  });
  const transition={type:'spring', duration:1}
  return (
    <div>
<video className='videoTag' autoPlay loop muted>
    <source src={vdo} type='video/mp4' />
    </video>
<div className="hero">

    <div className="left-h">
      {/* <Header/> */}
      <div className="the-best-comp">
       <motion.div
          animate={{ x: 90, borderRadius: 50 }}
          transition={{ repeat: Infinity, duration: 2}}

       
        ></motion.div>


        <span>The Best IT Company</span>
      </div>
      <div className="hero-text">
<span style={{fontWeight:'bold',color:'white',fontSize:'3rem'}}>
  {text}
</span>
 <div>
        <span>
      We care for your growth! Pay as you grow!
      </span>
      </div>
      </div>
     <div className="figures">
<div>
  <span> <NumberCounter end={100} delay={2} className="increment" postFix="+"/></span>
  <span>HAPPY CUSTOMERS</span>
</div>
<div>
  <span><NumberCounter end={20} delay={1} className="increment" postFix="+"/></span>
  <span>SKILLED EMPLOYEES</span>
</div>
<div>
  <span><NumberCounter end={100} delay={1} className="increment" postFix="+"/></span>
  <span>PROJECT COMPLETED</span>
</div>
<div>
  <span><NumberCounter end={10} delay={5} className="increment" postFix="+"/></span>
  <span>CHAMP TROPHY AWARDS</span>
</div>
     </div>
     <div className="hero-button">
      <button className="btn">Get Started</button>
      <button className="btn">Learn More </button>
     </div>
    </div>
   
  
    
</div>

    </div>
  )
}

export default Hero