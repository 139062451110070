import React,{useState,useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Whyus.css'
import {WhyusData }from '../../data/WhyusData'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'
import Slider from "react-slick";


const Whyus = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  },[] );
  var settings = {
    slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      
      loop:true,
      autoplay:true,
     
  
  
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            
         
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
           
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 3
          }
        }
      ]
    };
  const [selected, setSelected]= useState(0);
  const wLength=WhyusData.length
  return (
    <div>
     
        <div className="whyus">
           
            <div className="left-w">
                <span data-aos="fade-in">Why Us ?</span>
                <span data-aos="fade-in" data-aos-delay="100" >We care for your growth! Pay as you grow!</span>
                <span data-aos="fade-in" data-aos-delay="100">{WhyusData[selected].name}</span>
                <span data-aos="fade-in" data-aos-delay="100">{WhyusData[selected].brief}</span>
            </div>
           
            <div data-aos="fade-down-left" data-aos-delay="100" className="right-w">
           
              <div></div>
              <div></div>
              {/* <Slider {...settings}> */}
<img src={WhyusData[selected].image} alt="" />
 {/* </Slider> */}
<div className="arrows">
  <img 
  onClick={() =>{
    selected===0? setSelected(wLength-1):setSelected((prev)=>prev-1);
  }
  }src={leftArrow} alt="" />
  <img 
  onClick={() =>{
    selected===wLength-1? setSelected(0):setSelected((prev)=> prev+1);
  }}
  src={rightArrow} alt="" />
</div>
            </div>
           
        </div>
       
    </div>
  )
}

export default Whyus