import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Barcode.css'
import barcode3 from '../../../assets/barcode3.jpg'
import barcode1 from '../../../assets/barcode1.jpg'

const Barcode = () => {
    useEffect(()=>{
        Aos.init({duration:2000});
      },[] );
  return (
    <div>
          <div className="barcodebackk">

</div>
    <section id="gtco-special-dishes" className="bg-grey section-padding">
        <div className="container">
 <div className="row">
            <h1 style={{marginTop:'5rem',marginBottom:'10rem',textAlign:'center'}}>BARCODE SOLUTIONS SOFTWARE</h1>
            <div data-aos="zoom-in"className="col">
<img className='inventimg' src={barcode1 }alt="" />
            </div>
            <div data-aos="zoom-in"className="col">
                <h2 style={{marginTop:'3rem'}}>Barcode Software</h2>
                <p className='softtop-1'>Barcode Generation software is used to generate machine-readable code in the form of various numbers and parallel lines of different widths. ... Barcode tools integrate with inventory management systems, Patient Tracking Systems, Asset Management Systems, Vehicle Tracking Systems, Container Tracking Systems and Work Force Management Sytems etc., to keep track of information such as stock levels, product locations, and raw materials whenever the barcode is scanned.</p>
            </div>
        </div>
        <div style={{marginTop:'6rem'}} className="row">
            
            
            <div data-aos="zoom-in" className="col">
                <h2 style={{marginTop:'3rem'}}>LABEL DESIGNING SOFTWARE:</h2>
                <p className='softtop-1'> Label designing software adds a powerful, easy-to-use barcode label design application to business process that can be used by anyone from beginners to advanced users. Label designing software is the ideal package to design and manage labels and label printing system. For printing barcodes, text, graphic images or from databases, Label designing software has the features to help you design and print your labels / barcodes with ease.</p>
            </div>
            <div data-aos="zoom-in"className="col">
<img className='inventimg' src={barcode3 }alt="" />
            </div>
            
        </div>
        <div data-aos="zoom-in"className="card-invent">
            <div data-aos="zoom-in"className='barcodeimg'>
              
            </div>
            <div>
                <p>Linear Barcodes: Codabar, Code 39, Code 128, EAN-8, EAN-13 & many more...<br/>
2D, matrix, and stacked symbols: Aztec Code, Data Matrix, QR Code, PDF417, MaxiCode & many more...<br/>
Barcode standards : GS1 Coupons, GS1 Distribution, GS1 Health Care, GS1 Retails & many more...<br/></p>
            </div>
        </div>
    </div>
    </section>
    </div>
  )
}

export default Barcode