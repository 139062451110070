import React,{useEffect} from 'react'
import Aos from 'aos';
import "aos/dist/aos.css";
import './Contact.css'
import Header from '../Header/Header'

const Contact = () => {
   useEffect(()=>{
      Aos.init({duration:2000});
    },[] );
  return (
    <div>
    
  <div className="contact_section ">
  <div style={{ paddingTop:'2rem' ,paddingLeft:'2rem',paddingRight:'2rem'}}>
      <Header/>
      </div>  
         <div className="container">
            
            <h1 style={{color:'white',marginTop:'5rem'}}>Get In Touch</h1>
            <p className="contact_text">Get in touch and let us know how we can help. </p>
            <div className="row">
                    <div data-aos="zoom-in"className="col-sm-8 col-sm-offset-2 py-5">
                        <div className="row contact-details">
                     
                            <div className="col-sm-4 text-center">
                            <i className="fa fa-map-marker"></i>
                                <h6>Off: A-16, Street No 1, Dabri, Dashrathpuri, New. Delhi-110045</h6>
                            </div>
                            <div className="col-sm-4 text-center">
                                <i className="fa fa-envelope"></i>
                                <h6>infoestockerp@gmail.com</h6>
                            </div>
                            <div className="col-sm-4 text-center">
                                <i className="fa fa-phone"></i>
                                <h6>+91 92666 07886 / 8459490411</h6>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="contact_section_2 layout_padding">
               <div className="row">
                  <div data-aos="zoom-in"className="col-md-6">
                     <div className="contact_main">
                        <input type="text" className="mail_text" placeholder="Full Name" name="Full Name"/>
                        <input type="text" className="mail_text" placeholder="Phone Number" name="Phone Number"/>
                        <input type="text" className="mail_text" placeholder="Email" name="Email"/>
                        <textarea className="massage-bt" placeholder="Message" rows="5" id="comment" name="Message"></textarea>
                        <div className="send_bt"><a href="#">SEND</a></div>
                     </div>
                  </div>
                  <div data-aos="zoom-in" className="col-md-6">
                     <div className="map_main">
                        <div className="map-responsive">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d112189.03117515167!2d77.1370115!3d28.5124389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x17653f546368c95f!2sPRD%20Infotech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1676027095973!5m2!1sen!2sin" style={{width:"600px", height:"390px"}} allowfullscreen=""></iframe>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}

export default Contact